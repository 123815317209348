import {useEffect,useState,useContext} from 'react';
import Axios from "axios";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import { ContactForm } from './ContactFormDailog';
import { LoginForm } from './LoginFormDailog';
import { BackDropLoad } from './LoadingBackDrop';
import { UserContext } from './userContext';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import {Recommended} from "./Recommended";
import {Link,useNavigate, useParams} from 'react-router-dom';
import {Grid,Typography,useTheme,ButtonBase,Card,CardMedia,CardActions,makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem} from "@material-ui/core";
import parse from 'html-react-parser';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
   // RedditShareButton,
   // RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
   // ViberShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    
  } from "react-share";


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
export const EngWf=(props)=>{
    const id=useParams()
    const url=window.location.href;
    const theme = useTheme();
    const history=useNavigate();
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState();
     
    const {user, setUser} = useContext(UserContext);
    
    const [recommended,setRecommended]=useState();

    const [showContactForm,setShowContactForm]=useState(false);
    const [showLoginForm,setShowLoginForm]=useState(false);
    const [wait,setWait]=useState(false);

    const closeContactForm=()=>{
        setShowContactForm(false);
    }


    const closeLoginForm=()=>{
        setShowLoginForm(false);
    }


    const showbackgroundLoad=()=>{
        setWait(true);
    }

    const hidebackgroundLoad=()=>{
        setWait(false);
    }

    const openContactForm=()=>{
        
        setShowContactForm(true);
    }

    useEffect(()=>{
        setLoading(true);
        window.scrollTo(0, 0);
        // var id = props.id;
        const formData= {};
        if(id?.id===0 || isNaN(id?.id)){
            history('/');
        }else{
           formData.id=id?.id;
           
        }
        Axios.post("/new_apis/get-single-engwf",formData,{ 
            headers: { key: key },
           }).then((res) => {
               if(res.data.status){
                  setRecommended(res.data.recommended);
                  
                  setData(res.data.data[0]);
                    
                  
                  setLoading(false);
               }else  {
                  history('/');
               }
              
           });
    },[id?.id]);

    return (<div className="container">{loading?<Loading />:<div>
        {data && (<Helmet>
                    <title>{data.fname} - {data.subcat} | ESAY - Engineering Sources Around You</title>
                     
                </Helmet>)}
                <br/>
        <Box  style={{ border:"5px solid #ffd24d",backgroundColor:"white",borderRadius:"5px"}} m={3} p={3}>
        {data && (<Grid
            container
            direction="row"
  justify="space-between"
  alignItems="flex-start"
            spacing={3}
            >
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                 
                 {data && (<Box p={3} >
                 <div  
                    //className="MuiPaper-elevation1 text-center"
                    style={{
                    // // margin:"5px",
                    //  border:"2px groove #ffd24d",
                    // // padding:"5px",
                
                    // borderRadius:"5px",
                    // // // maxHeight:"300px"
                    // // // filter: "blur(5px)",
                     
                    // // //width: "300px", 
                    // alignItems: "center",
                    // // height: "350px",
                     
                    }}
                    >
                    
                        <img className="img img-fluid" src={data.category_ids==="7" ? "/assets/uploads/images/engineers.jpg":"/assets/uploads/images/workforce.jpg"} style={{borderRadius:"5px",maxHeight:"100%"   }}   alt={data.subcat} />
                </div>
                </Box>)}
            </Grid>    
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Box p={3}  >
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    spacing={2}
                    >
                        <Grid item xs={12}>
                         <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className="text-center" gutterBottom style={{color:theme.palette.secondary.main   }}>
                                     {data.fname+" "+data.lname}{} 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Category:</b>  { data.cat } - { data.subcat }                 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Location:</b>  { data.area_ids }                   
                        </Typography>
                    </Grid>
                    {(data.qualification!==null && data.qualification!=='')&&(<Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Educational Background:</b>  { data.qualification }                   
                        </Typography>
                    </Grid>)}
                    {(data.exp!==null && data.exp!=='')&&(<Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Years of Experience:</b>  { data.exp }                   
                        </Typography>
                    </Grid>)}
                    {(data.certified_courses!==null && data.certified_courses!=='')&&(<Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Certifications:</b>  { data.certified_courses }                   
                        </Typography>
                    </Grid>)}
                    <BackDropLoad wait={wait}/>
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Share Options:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TwitterIcon size={30} round={true}/>
                        </TwitterShareButton>    
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        </Typography>
                        
                     </Grid>
                    {!user && (<LoginForm open={showLoginForm} showload={showbackgroundLoad} hideload={hidebackgroundLoad} closeform={closeLoginForm} opencontactform={openContactForm} />)}
                    {user && (<ContactForm open={showContactForm} showload={showbackgroundLoad} hideload={hidebackgroundLoad} closeform={closeContactForm} data={data} url={"engwf"} />)}
                    <Grid item xs={12} className="text-center" >
                        <Box m={2}>
                        <Button color="primary" 
                        variant="contained" 
                        
                        style={{width:"70%"}}
                        className="linkstyle" 
                        onClick={()=>{
                            if(user){
                                setShowContactForm(true);
                                 
                             }else{
                                 setShowLoginForm(true);
                             }
                        }}
                        >
                         Contact Now
                        </Button>
                        </Box>
                    </Grid> 
                     
                     
                </Grid>
                </Box>
            </Grid>
        </Grid>)}
        </Box>
        <br /> 
        <Recommended recs={recommended}/>

    </div>}<br/><br/><br/></div>);

}