import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet  } from 'react-helmet-async';

import {withStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Terms=()=>{
  
  const theme = useTheme();
  const [metadata,setMetadata] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"terms"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="md">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                     Terms and Conditions          
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={1}
                        >
                          <Grid item xs={12}>
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main   }}>
                            Our Terms & Conditions:
                           </Typography>
                           <ol>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                              Please Enter only relevant details at the time of Registration.
                              </Typography>
                             </li>
  
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             After Registration as Material & Machinery suppliers, Testing laboratories, Consultants or Contractors can add their product / service by clicking the “My Products / Services” tab in their Dashboard and finally they need to choose a Subscription plan in the "My Subscription" tab of their Dashboard which will activate the “Contact Now” for their Product / Service for their desired customers to contact them.
                              </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             Every time a material or machinery supplier or testing laboratory or consultant or contractor  adds/updates their product/service Details, they will be validated and approved/rejected by the backend team. So, Ensure that relevant information alone is provided in the respective fields.
                              </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                              Their is no Subscription plan for Engineers and Workforce. their profile will be visible based on their profile visiblity value at the time of Registration. that can be anytime changed from show to hide or hide to show from the "Update Details" Tab in their "My Account" .
                              </Typography>
                             </li>
                              
                            
                           </ol>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main   }}>
                            Payment Failure & Refund Policy:
                           </Typography>
                            <ol>
                            <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             Once a payment is accepted and a Subscription plan is issued, Their will be no Refunds.
                               </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             In case if the Amount got Debited the subscription is not issued, the Debited amount be refunded back to your source account in 3 to 5 Days.
                               </Typography>
                             </li>
                            </ol>
                          </Grid>

                          <Grid item xs={12}>
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main   }}>
                            Disclaimer:
                           </Typography>
                            <ol>
                            <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             ESAY gives no guarantee to the users of the portal that registration would result in conclusion of a business offer. The information provided in the portal would be made available to the cross section of Construction industry.
                               </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             The inclusion of contact not necessarily imply a recommendation.
                               </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body2"    style={{color:"white"}}>
                             Considerable efforts and care is taken to incorporate the useful information to the Engineers and people in the construction sector. In case of any errors, discrepancies or omissions in the information provided, the ESAY shall not be liable. The users are requested to relay on professional advice / expertise.
                               </Typography>
                             </li>
                            </ol>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 