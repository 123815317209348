import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {withStyles} from '@material-ui/core/styles';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const FAQs=()=>{
  
  const theme = useTheme();
  const [metadata,setMetadata] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"faqs"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="md">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                     Frequently Asked Questions      
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={1}
                        >
                           <Grid item xs={12}></Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. How to Change My Role From <b  >Regular User</b> to <b>Contractor</b> / <b>Consultant</b> / <b>Machinery Supplier</b> / <b>Material Supplier</b> / <b>Testing Laboratory</b> / <b>Engineer</b> / <b>Workforce</b>  ?
                               </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. Go to 'My Account' -> 'Upgrade Profile',  Choose your desired Category that you want to upgrade to, fill all the Mandatory Fields and tap on 'Upgrade Profile' to Upgrade your Profile Successfully.
                               </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. How to Add Details about my <b>Product / Service</b> ?
                                </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. Go to - 'My Account' -> 'My Products / Services' -> Tap on 'Add Product / Service',  The Candidate should give details about Product or Service like: Name of Product / Service, SubCategory Name, Images of Service / Product (Maximum Image Limit is 3), Mobile Number, Email, Location, Short Description & Long Description and Tap on 'Add Details'. After Successfully Adding Details, it will be verified by our Backend team. Once, Approved it will be for your Customers to View your Service / Product Details.
                               </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. Why there is no <b  >Contact Now</b> Button for my Active Product / Service ?
                               </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. To a Contact Now Button for your Product / Service to get Desired Customers for their Product / Service, the Candidate should choose a subscription plan from 'My Account' -> 'My Subscription'.
                                </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. How to <b  >Show or Hide</b> my <b>Engineer</b> / <b>Workforce</b> profile from Public ?
                               </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. You can change your profile's public visibility (Show or Hide your profile from public) at 'My Account' -> 'Update Details'.
                               </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. What will happen if payment was successful and Subscription not given ? 
                                </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. In case if the Amount got Debited the subscription is not issued, the Debited amount be refunded back to your source account in 3 to 5 Days.
                                </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <Typography variant="body2"  style={{color:theme.palette.primary.main,padding:"5px"   }}>
                               Q. Is the Subscription Amount Refundable ?
                                </Typography>
                               <Typography variant="body2"  style={{padding:"5px"}}>
                               Ans. Once a payment is accepted and a Subscription plan is issued, Their will be no Refunds.
                                </Typography>
                           </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 