import React from "react";
 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import "../App.css";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import XIcon from '@mui/icons-material/X';

 export const Footer=()=> {
  return (
    <div className="main-footer">
        <Container maxWidth="lg" fixed>
        <Grid
          spacing={5}
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}  >
                <Typography variant="body1" gutterBottom>
                   <center>  ABOUT US</center>
                </Typography>
                <Typography variant="body2" style={{'text-align': "left"}}>
                The ESAY portal has an ever expanding database of the contacts in the construction sector that can be instrumental for your project and the latest construction related information would be available on our website.. <Link to="/about"  className="anchorTag"  ><b>know more</b></Link>

                </Typography>
                 
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}  >
                <Typography variant="body1" gutterBottom>
                <center> ADDITIONAL LINKS</center>
                </Typography>
                <Typography variant="body2">
                <ul style={{lineHeight:1.4}} >
                    <li><Link to="/guide"   className="anchorTag" style={{padding:10}}>Guide.</Link></li>
                    <li><Link to="/faqs"   className="anchorTag" style={{padding:10}}>Frequently Asked Questions.</Link></li>
                    <li><Link to="/acknowledgments"   className="anchorTag" style={{padding:10}}>Acknowledgments.</Link></li>
                    <li><Link to="/terms"   className="anchorTag" style={{padding:10}}>Terms and Conditions.</Link></li>
                    <li><Link to="/contact-now"  className="anchorTag" style={{padding:10}}>Contact us.</Link></li>
                    <li><Link to="/privacy_policy"  className="anchorTag" style={{padding:10}}>Privacy Policy.</Link></li>
                   
                </ul>
                </Typography>
                 
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}  >
                    <Typography variant="body1" gutterBottom>
                    <center>   CONTACT US </center>
                    </Typography>
                    
                    <p><b>Address: </b>A-BLOCK, 3rd floor, Sandil, Jayabheri enclave, Gachibowli, Hyderabad - 500032</p>
                    <p><b>Email: </b>contact@esay.in, contact@arawinz.com.</p>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        
                        spacing={1}
                        >
                        <Grid item  >
                            <p><b>Follow us on: </b> </p>
                        </Grid>    
                        <Grid item  >
                            <a href="https://www.facebook.com/EsayConstructionalServices" target="_blank" style={{color:"black",textDecoration:"none"}}><FacebookIcon  style={{fontSize:20}} /></a>
                        </Grid>
                        <Grid item >
                        <a href="https://www.instagram.com/esayindia" target="_blank" style={{color:"black",textDecoration:"none"}}> <InstagramIcon style={{fontSize:20}} /></a>
                        </Grid>
                        <Grid item >
                        <a href="https://twitter.com/EsayIndia" target="_blank" style={{color:"black",textDecoration:"none"}}><XIcon  style={{fontSize:20}} /></a>
                        </Grid>
                        <Grid item  >
                            <a href="https://www.youtube.com/channel/UCzYGKghiLJ9Vku678tvIASQ" target="_blank" style={{color:"black",textDecoration:"none"}}> <YoutubeIcon  style={{fontSize:20}}  /></a>
                        </Grid>
                        <Grid item  >
                            <a href="https://www.linkedin.com/company/esay-engineering-sources-around-you" target="_blank" style={{color:"black",textDecoration:"none"}}> <LinkedInIcon  style={{fontSize:20}}  /></a>
                        </Grid>
                        <Grid item >
                            <a href="https://in.pinterest.com/esayindia" target="_blank" style={{color:"black",textDecoration:"none"}}> <PinterestIcon  style={{fontSize:20}}  /></a>
                        </Grid>
                    </Grid>
                    
                    
                   
                    
                    
                    
                    
                   
                    
                     
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="body1" gutterBottom style={{marginBottom:"7%"}}>
                    <center>  DOWNLOAD THE APP </center>
                    </Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                >
                    <Grid item xs={12} className="text-center">
                    <a href="https://play.google.com/store/apps/details?id=com.esay.arawinzsoft" target="_blank"><img src="/assets/gplay.png" className="img img-fluid" alt="ESAY ANDROID APP" style={{height:"60px"}}/></a>
                    </Grid>
                    <Grid item xs={12}  className="text-center">
                    <a href="https://apps.apple.com/us/app/esay/id1490576735?ls=1" target="_blank"><img src="/assets/iosstore.png"  className="img img-fluid" alt="ESAY IOS APP" style={{height:"60px"}}/></a>
                    </Grid>
            </Grid>
                <br/>
            </Grid>
                 
        </Grid>
         
        </Container>
         <br/>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
             
            style={{backgroundColor:"black"}}
            >
                <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} >
                <br/>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                <Typography variant="body1" gutterBottom style={{color:"#ffd24d",textAlign:"center"}}>
                Powered by    <a href="https://arawinz.com" target="_blank" style={{"color":"#ffffff"}}>Arawinz soft solutions pvt ltd. © 2018 - 2024</a>
                </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <br/>
                </Grid>
            </Grid>
         
    </div>);
}

 